<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'worksheetTypeDefinition', params: {} }"
          active
        >
          Worksheet Type
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <v-btn
          class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
          color="primary"
          :to="{ name: 'worksheetTypeDefinitionCreate', params: {} }"
        >
          Create Worksheet Type
        </v-btn>
      </div>

      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="worksheetTypeDefinitionList"
          :items-per-page="pagination.pageSize"
          :loading="!worksheetTypeDefinitionProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-pagination="
            !worksheetTypeDefinitionList ||
            worksheetTypeDefinitionList.length < 1
          "
          :hide-default-header="
            !worksheetTypeDefinitionList ||
            worksheetTypeDefinitionList.length < 1
          "
          :hide-default-footer="
            !worksheetTypeDefinitionList ||
            worksheetTypeDefinitionList.length < 1
          "
          :disable-sort="true"
          item-key="id"
          class="elevation-1"
          @update:options="pageChanged"
        >
          <template v-slot:item.worksheetType="{ item }">
            <a @click="view(item)">{{ item.worksheetType }}</a>
          </template>

          <template v-slot:item.hasWeightSettings="{ item }">
            <a @click="view(item)">{{
              item.hasWeightSettings ? "Yes" : "No"
            }}</a>
          </template>

          <template v-slot:item.hasLocation="{ item }">
            <a @click="view(item)">{{ item.hasLocation ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasProcessType="{ item }">
            <a @click="view(item)">{{ item.hasProcessType ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasProduct="{ item }">
            <a @click="view(item)">{{ item.hasProduct ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasDeductibleWeight="{ item }">
            <a @click="view(item)">{{
              item.hasDeductibleWeight ? "Yes" : "No"
            }}</a>
          </template>

          <template v-slot:item.hasTargetWeight="{ item }">
            <a @click="view(item)">{{ item.hasTargetWeight ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasNetAdjustmentWeight="{ item }">
            <a @click="view(item)">{{
              item.hasNetAdjustmentWeight ? "Yes" : "No"
            }}</a>
          </template>

          <template v-slot:item.hasDocumentNumber="{ item }">
            <a @click="view(item)">{{ item.hasDocumentNumber ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasSignature="{ item }">
            <a @click="view(item)">{{ item.hasSignature ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasMemo="{ item }">
            <a @click="view(item)">{{ item.hasMemo ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasEntryPhoto="{ item }">
            <a @click="view(item)">{{ item.hasEntryPhoto ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasNotes="{ item }">
            <a @click="view(item)">{{
              item.hasNotes ? "Yes" : "No"
            }}</a>
          </template>

          <template v-slot:item.hasEntryNotes="{ item }">
            <a @click="view(item)">{{ item.hasEntryNotes ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasSupplier="{ item }">
            <a @click="view(item)">{{ item.hasSupplier ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.hasCustomer="{ item }">
            <a @click="view(item)">{{ item.hasCustomer ? "Yes" : "No" }}</a>
          </template>

          <template v-slot:item.createDate="{ item }">
            <a @click="view(item)">{{ item.createDate }}</a>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
            <v-icon small class="mr-2" @click="remove(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      worksheetTypeDefinitionList: [],
      showParentPage: true,

      headers: [
        {
          text: "Type",
          value: "worksheetType",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Weight Setings",
          value: "hasWeightSettings",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Location",
          value: "hasLocation",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Process Type",
          value: "hasProcessType",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Product",
          value: "hasProduct",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Deductible Weight",
          value: "hasDeductibleWeight",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Target Weight",
          value: "hasTargetWeight",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Inventory Selection",
          value: "hasInventorySelection",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Net Adjustment Weight",
          value: "hasNetAdjustmentWeight",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Document Number",
          value: "hasDocumentNumber",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Signature",
          value: "hasSignature",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Memo",
          value: "hasMemo",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Require Photo",
          value: "hasEntryPhoto",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Notes",
          value: "hasNotes",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Entry Notes",
          value: "hasEntryNotes",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Supplier",
          value: "hasSupplier",
          align: "start",
          sortable: false,
        },
        {
          text: "Has Customer",
          value: "hasCustomer",
          align: "start",
          sortable: false,
        },
        {
          text: "Created On",
          value: "createDate",
          align: "start",
          sortable: false,
        },

        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      sort: {
        key: null,
      },
      searchTerms: "",
    };
  },
  computed: {
    ...mapGetters("worksheetTypeDefinition", [
      "worksheetTypeDefinitionProcessed",
      "getWorksheetTypeDefinitions",
    ]),
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("worksheetTypeDefinition", [
      "deleteWorksheetTypeDefinition",
      "fetchWorksheetTypeDefinitions",
    ]),
    view(item) {
      this.$router.push({
        name: "worksheetTypeDefinitionView",
        params: { id: item.id },
      });
    },
    edit(item) {
      this.$router.push({
        name: "worksheetTypeDefinitionEdit",
        params: { id: item.id },
      });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this worksheetTypeDefinition?"
      );
      if (confirm) {
        this.deleteWorksheetTypeDefinition(item.id)
          .then(() => {
            this.addSuccessMessage(
              "worksheetTypeDefinition was deleted successfully"
            );
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
      };
      if(this.searchTerms){
        params.worksheetType = this.searchTerms;
      }
      
      this.fetchWorksheetTypeDefinitions(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            this.worksheetTypeDefinitionList = response.content;
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;
      this.getResources();
    },
    search() {
      this.getResources();
    },
    clearSearch() {
      this.searchTerms = null;
      this.getResources();
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>
